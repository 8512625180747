export const ROUTE = {
  SIGNIN: "/",
  HANDLECALLBACK: "/callback",
  DASHBOARD: "/dashboard",
  DASHBOARDV2: "/dashboardv2",
  CONNECTGITHUB: "/connectGithub",
  NEWWEBAPP: "/new-webapp",
  SERVICEDETAIL: "/vm-instance/detail",
  VMINSTANCE: "/vm-instance",
  CONNECTSSH: "/connect-ssh",
  CONNECTVM: "/dashboard/VM-connect",
  OCEAN: "/ocean",
  WZARD: "/wzard",
  DASHBOARDVM: "/dashboard-vm",
  DASHBOARDHOME: "/dashboard-home",
};

export const TYPE = { WEBAPP: "Web Service" };
